import {motion, useScroll} from "framer-motion";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.backgroundColor};
  width: 100vw;
  justify-content: center;
  padding-left: 20px;

  h3 {
    color: ${props => props.theme.textColor};
    font-weight: normal;
    font-size: 2.25em;
    line-height: 1.5em;

    &.name {
      text-align: right;
    }

    &.description {
    }
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6em;
`;

const NamesContainer = styled.div`
`;

const DescrContainer = styled.div`
  padding-left: 4px;
`;

interface ItemProps {
  label: string,
  type: 'name' | 'description'
}

function Item({label, type}: ItemProps) {
  return (
    <motion.h3
      className={type}
      initial={{
        opacity: 0
      }}
      whileInView={{
        opacity: 1
      }}
      transition={{duration: 0.5}}
      viewport={{once: true, amount: 0.8}}
    >
      {label}
    </motion.h3>

  );
}

export default function Projects() {
  const {scrollYProgress} = useScroll();

  return (
    <Container>
      <ScrollContainer>
        <NamesContainer>
          {projectList.map(project => <Item label={project.name} type={"name"}/>)}
        </NamesContainer>
        <DescrContainer>
          {projectList.map(project => <Item label={project.description} type={"description"}/>)}
        </DescrContainer>
      </ScrollContainer>
    </Container>);
}

interface IProject {
  name: string,
  description: string,
}

const projectList: IProject[] = [
  {
    name: 'a green day',
    description: 'and more',
  },
  {
    name: 'lavia',
    description: 'natural home schooling',
  },
  {
    name: 'happy kids',
    description: 'eco to edu',
  },
  {
    name: 'noi studio',
    description: 'open own gate',
  },
  {
    name: 'the kitchen story',
    description: 'eat with feel',
  },
  {
    name: 'om',
    description: 'happier',
  },
  {
    name: 'AQ furniture',
    description: 'inner profession',
  },
  {
    name: 'an nam',
    description: 'piece space',
  },
  {
    name: 'ot house',
    description: 'order from chaos',
  },
  {
    name: 'nho oi',
    description: "thread's breath",
  },
  {
    name: 'dc studio',
    description: 'base on "c"',
  },
  {
    name: 'kt house',
    description: 'creating nest',
  },
  {
    name: 'mot',
    description: 'reading together',
  },
  {
    name: 'the powerhouse',
    description: 'power of romantic',
  },
  {
    name: 'wonderland',
    description: 'every child, every wonder',
  },
  {
    name: '...',
    description: ''
  }
]