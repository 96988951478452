import React, {useCallback, useState} from 'react';
import styled from "styled-components";

type GalleryImageProps = {
  src: string,
  srcHover?: string,
  srcClick?: string,
  width: number | string,
  height: string | number,
  alt?: string,
};

interface StyledGalleryImageProps {
  width: number | string,
  height: number | string,
  image: string,
}

const StyledGalleryImage = styled.div<StyledGalleryImageProps>`
  width: ${p => p.width};
  height: ${p => p.height};
  background: url(${p => p.image}) no-repeat;
`;

export default function GalleryImage(props: GalleryImageProps) {
  const [image, setImage] = useState(props.src);

  const onHover = useCallback(() => {
    setImage(props.srcHover ? props.srcHover : props.src);
  }, [props.src, props.srcHover]);

  const resetImage = useCallback(() => {
    setImage(props.src);
  }, [props.src]);

  const onSelectImage = useCallback(() => {
    setImage(props.srcClick ? props.srcClick : props.src);
  }, [props.src, props.srcClick])

  return (
    <StyledGalleryImage height={props.height} width={props.width} image={image}
                        onMouseOver={onHover} onMouseOut={resetImage} onClick={onSelectImage}/>
  );
}