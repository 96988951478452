import React from 'react';
import styled from "styled-components";

const BannerContainer = styled.div`

`;

const Image = styled.img`
  width: 100vw;
  height: 100vh;
`;

export interface BannerProps {
  isMobile: boolean
}

export default function Banner(props: BannerProps) {

  return (
    <BannerContainer>
      <Image src={'/bannerBg.jpg'}/>
    </BannerContainer>
  )
    ;
}