import React, {useEffect, useRef, useState} from 'react';
import DocumentTitle from 'react-document-title';
import Header from "./Header";
import Banner from "./Banner";
import styled, {ThemeProvider} from "styled-components";
import {useInView} from "framer-motion";
import Slogan from "./Slogan";
import Projects from "./Projects";
import Gallery from "./Gallery";
import Footer from "./Footer";
import {darkModeColor, DefaultColorTheme, whiteModeColor} from "./theme/DefaultColorTheme";

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  :hover {
    opacity: 1 !important;
    transition-duration: 0.5s;
  }
`;

const HomeContainer = styled.div`
  width: 100vw;
  color: #697b8c;

  section {
    width: 100vw;
    display: flex;
    scroll-snap-align: center;
    overflow-x: hidden;
    background-color: ${props => props.theme.backgroundColor};
    padding-bottom: 100px;

    &.light {
      background-color: #ffffff !important;
    }
  }
`;

export default function Home() {
  const bannerRef = useRef(null);
  const projectRef = useRef(null);
  const galleryRef = useRef(null);
  const footerRef = useRef(null);
  const isBannerInView = useInView(bannerRef);
  const isProjectInView = useInView(projectRef);
  const isGalleryInView = useInView(galleryRef);
  const isFooterInView = useInView(footerRef);
  const [themeColor, setThemeColor] = useState(DefaultColorTheme);

  const isMobile = false; // todo

  useEffect(() => {
    if (!isProjectInView && (isGalleryInView || isFooterInView)) {
      setThemeColor(whiteModeColor);
    } else {
      setThemeColor(darkModeColor);
    }
  }, [isFooterInView, isGalleryInView, isProjectInView]);

  return (
    <ThemeProvider theme={themeColor}>
      <DocumentTitle title="Toby Designers">
        <div>
          <HomeContainer>
            <HeaderContainer style={{opacity: isBannerInView ? 0 : 1}}>
              <Header isMobile={isMobile}/>
            </HeaderContainer>
            <section ref={bannerRef}>
              <Banner isMobile={isMobile}/>
            </section>
            <section>
              <Slogan/>
            </section>
            <section ref={projectRef}>
              <Projects/>
            </section>
            <section className={'light'} ref={galleryRef}>
              <Gallery/>
            </section>
            <section className={'light'} ref={footerRef}>
              <Footer/>
            </section>
          </HomeContainer>
          <div/>
        </div>
      </DocumentTitle>
    </ThemeProvider>
  );
}