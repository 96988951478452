import React from 'react';
import styled from "styled-components";
import GalleryImage from "./GalleryImage";

const Container = styled.div`
  width: 70%;
  padding-top: 250px;
  margin: auto;
`;

const GridContainer = styled.div`
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(5, 256px);
  grid-template-rows: repeat(5, 256px);
  width: 1304px;
`;

const GridItem = styled.div`
`;

const LongGridItem = styled.div`
  grid-column: span 2;
`;

const BigGridItem = styled.div`
  grid-column: span 2;
  grid-row: span 2;
`;

export default function Gallery() {

  return (
    <Container>
      <GridContainer>
        {/*Row 1*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_0_grey.svg'}
            srcHover={'gallery/Logo_0_hover.svg'}
            srcClick={'gallery/Logo_0_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_1_grey.svg'}
            srcHover={'gallery/Logo_1_hover.svg'}
            srcClick={'gallery/Logo_1_selected.svg'}
          />
        </GridItem>
        <LongGridItem>
          <GalleryImage
            width={'518px'}
            height={'256px'}
            src={'gallery/Logo_2_grey.svg'}
            srcHover={'gallery/Logo_2_hover.svg'}
            srcClick={'gallery/Logo_2_selected.svg'}
          />
        </LongGridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_3_grey.svg'}
            srcHover={'gallery/Logo_3_hover.svg'}
            srcClick={'gallery/Logo_3_selected.svg'}
          />
        </GridItem>
        {/*Row 2*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_4_grey.svg'}
            srcHover={'gallery/Logo_4_hover.svg'}
            srcClick={'gallery/Logo_4_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_5_grey.svg'}
            srcHover={'gallery/Logo_5_hover.svg'}
            srcClick={'gallery/Logo_5_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_6_grey.svg'}
            srcHover={'gallery/Logo_6_hover.svg'}
            srcClick={'gallery/Logo_6_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_7_grey.svg'}
            srcHover={'gallery/Logo_7_hover.svg'}
            srcClick={'gallery/Logo_7_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_8_grey.svg'}
            srcHover={'gallery/Logo_8_hover.svg'}
            srcClick={'gallery/Logo_8_selected.svg'}
          />
        </GridItem>
        {/*Row 3*/}
        <LongGridItem>
          <GalleryImage
            width={'518px'}
            height={'256px'}
            src={'gallery/Logo_9_grey.svg'}
            srcHover={'gallery/Logo_9_hover.svg'}
            srcClick={'gallery/Logo_9_selected.svg'}
          />
        </LongGridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_10_grey.svg'}
            srcHover={'gallery/Logo_10_hover.svg'}
            srcClick={'gallery/Logo_10_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_11_grey.svg'}
            srcHover={'gallery/Logo_11_hover.svg'}
            srcClick={'gallery/Logo_11_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_12_grey.svg'}
            srcHover={'gallery/Logo_12_hover.svg'}
            srcClick={'gallery/Logo_12_selected.svg'}
          />
        </GridItem>
        {/*Row 4*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_13_grey.svg'}
            srcHover={'gallery/Logo_13_hover.svg'}
            srcClick={'gallery/Logo_13_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_14_grey.svg'}
            srcHover={'gallery/Logo_14_hover.svg'}
            srcClick={'gallery/Logo_14_selected.svg'}
          />
        </GridItem>
        <LongGridItem>
          <GalleryImage
            width={'518px'}
            height={'256px'}
            src={'gallery/Logo_15_grey.svg'}
            srcHover={'gallery/Logo_15_hover.svg'}
            srcClick={'gallery/Logo_15_selected.svg'}
          />
        </LongGridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_16_grey.svg'}
            srcHover={'gallery/Logo_16_hover.svg'}
            srcClick={'gallery/Logo_16_selected.svg'}
          />
        </GridItem>
        {/*Row 5*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_17_grey.svg'}
            srcHover={'gallery/Logo_17_hover.svg'}
            srcClick={'gallery/Logo_17_selected.svg'}
          />
        </GridItem>
        <BigGridItem>
          <GalleryImage
            width={'518px'}
            height={'518px'}
            src={'gallery/Logo_18_grey.svg'}
            srcHover={'gallery/Logo_18_hover.svg'}
            srcClick={'gallery/Logo_18_selected.svg'}
          />
        </BigGridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_19_grey.svg'}
            srcHover={'gallery/Logo_19_hover.svg'}
            srcClick={'gallery/Logo_19_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_20_grey.svg'}
            srcHover={'gallery/Logo_20_hover.svg'}
            srcClick={'gallery/Logo_20_selected.svg'}
          />
        </GridItem>
        {/*Row 6*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_21_grey.svg'}
            srcHover={'gallery/Logo_21_hover.svg'}
            srcClick={'gallery/Logo_21_selected.svg'}
          />
        </GridItem>
        <LongGridItem>
          <GalleryImage
            width={'518px'}
            height={'256px'}
            src={'gallery/Logo_22_grey.svg'}
            srcHover={'gallery/Logo_22_hover.svg'}
            srcClick={'gallery/Logo_22_selected.svg'}
          />
        </LongGridItem>
        {/*Row 7*/}
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_23_grey.svg'}
            srcHover={'gallery/Logo_23_hover.svg'}
            srcClick={'gallery/Logo_23_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_24_grey.svg'}
            srcHover={'gallery/Logo_24_hover.svg'}
            srcClick={'gallery/Logo_24_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_25_grey.svg'}
            srcHover={'gallery/Logo_25_hover.svg'}
            srcClick={'gallery/Logo_25_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_26_grey.svg'}
            srcHover={'gallery/Logo_26_hover.svg'}
            srcClick={'gallery/Logo_26_selected.svg'}
          />
        </GridItem>
        <GridItem>
          <GalleryImage
            width={'256px'}
            height={'256px'}
            src={'gallery/Logo_27_grey.svg'}
            srcHover={'gallery/Logo_27_hover.svg'}
            srcClick={'gallery/Logo_27_selected.svg'}
          />
        </GridItem>
      </GridContainer>
    </Container>
  );
}
