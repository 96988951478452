import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  gap: 6px;
  background-color: ${props => props.theme.backgroundColor};
`;

const CenterContainer = styled.div`
  align-self: center;
  text-align: center;
  position: relative;
`;

const Text = styled.h1`
  font-weight: bold;
  color: ${props => props.theme.textColor};

  &.action {
    text-align: right;
  }

  &.object {
    text-align: left;
  }
`;

export default function Slogan() {

  return (
    <Container>
        <CenterContainer>
          <Text>Design your IDeals</Text>
        </CenterContainer>
    </Container>);
}

const actionItems = [
  'know',
  'concept',
  'refine',
  'design',
  'detail',
  'show'
];
const objectItems = [
  'logo',
  'ideal',
  'visual',
  'business',
  'story'
]