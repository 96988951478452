type ColorSet = {
  backgroundColor: string,
  textColor: string
}

export const darkModeColor: ColorSet = {
  backgroundColor: "#262626",
  textColor: "#FFFFFF"
};

export const whiteModeColor: ColorSet = {
  backgroundColor: "#FFFFFF",
  textColor: "#262626"
};

export const DefaultColorTheme: ColorSet = {
  backgroundColor: darkModeColor.backgroundColor,
  textColor: darkModeColor.textColor
}