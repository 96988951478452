import React from 'react';
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 80vh;
  width: 100%;
`;

const TextContainer = styled.div`
  align-self: center;
`;

const BlackText = styled.span`
  font-size: 2em;
  color: ${props => props.theme.textColor};
`;

const ColorText = styled.span`
  font-size: 2em;
  color: #FCD455;
`;

export default function Footer() {
  return (
    <Container>
      <TextContainer>
        <BlackText>God is Good</BlackText><ColorText> Designers</ColorText>
      </TextContainer>
    </Container>);
}
