import {Col, Menu, Row} from 'antd';
import React, {useState} from 'react';
import styled from "styled-components";

const menuItems = [
  {
    key: 'notes',
    label: 'notes',
  },
  {
    key: 'designs',
    label: 'designs',
  },
  {
    key: 'stories',
    label: 'stories',
  },
  {
    key: 'timeline',
    label: 'timeline',
  },
  {
    key: 'me',
    label: 'me',
  },
];

const HeaderContainer = styled.div`
  height: 120px;
  background-color: ${props => props.theme.backgroundColor};
  position: relative;
  transition-duration: 2s;
  display: flex;
  flex-direction: column-reverse;
`;

const StyledRow = styled(Row)`
  padding-bottom: 2px;
`;

const MenuContainer = styled.div`
  float: right;
  overflow: hidden;
  height: 64px;
  padding-right: 20px;

  .ant-menu {
    line-height: 60px;
    background: transparent !important;
  }

  .ant-menu-horizontal {
    border-bottom: none;

    & > .ant-menu-item {
      color: ${props => props.theme.textColor};

      span {
        font-family: 'Literata', 'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: ${props => props.theme.textColor};
        font-size: 2em;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > .ant-menu-item-selected {
      background-color: transparent;

      span {
        color: ${props => props.theme.textColor};
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`;

const LogoContainer = styled.div`
  overflow: hidden;
  padding-left: 40px;
  float: left;
  text-decoration: none;

  .text {
    font-family: 'Literata', 'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 3em;
    font-weight: bold;
    color: ${props => props.theme.textColor};
  }
`;

interface HeaderProps {
  isMobile: boolean
}

type MenuMode = 'horizontal' | 'vertical' | 'inline';

export default function Header(props: HeaderProps) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuMode, setMenuMode] = useState<MenuMode>('horizontal');

  const menu = (
    <Menu
      mode={menuMode}
      items={menuItems}
      disabledOverflow={true}
    >
      {menuItems.map(item =>
        (<Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>))}
    </Menu>
  );

  return (
    <HeaderContainer>
      <StyledRow>
        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
          <LogoContainer>
            <div className="text">Toby</div>
          </LogoContainer>
        </Col>
        <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
          <div className="header-meta">
            {menuMode === 'horizontal' ?
              <MenuContainer id="menu">{menu}</MenuContainer>
              : null}
          </div>
        </Col>
      </StyledRow>
    </HeaderContainer>
  );
}